import RulerControlBase from '@mapbox-controls/ruler';
import '@mapbox-controls/ruler/src/index.css';
import type { IControl } from 'mapbox-gl';
import { useControl } from 'react-map-gl';

type RulerControlProps = {
  position?: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  units?: 'kilometers' | 'miles' | 'meters';
};

class MapRulerControl implements IControl {
  _control: RulerControlBase;

  constructor(options: RulerControlProps) {
    this._control = new RulerControlBase({
      units: options.units,
      labelFormat: (n: number) =>
        `${n.toFixed(2)} ${options.units === 'miles' ? 'mi' : 'km'}`,
    });
  }

  onAdd(map: mapboxgl.Map) {
    return this._control.onAdd(map);
  }

  onRemove() {
    this._control.onRemove();
  }
}

export function RulerControl({
  position = 'top-right',
  units = 'miles',
}: RulerControlProps) {
  useControl(() => new MapRulerControl({ units }), { position });
  return null;
}
